<!-- todo: should be refactored to re-use FormItemTextfield -->

<template>
  <div class="form-item-email">
    <label :for="element.key">{{ element.title }}</label>
    <input :id="element.key" :required="element.required ?? false" :name="element.key" type="email" :value="prefill">
  </div>
</template>
<script setup lang="ts">
import { type WebformElementTextBaseFragment, type WebformSubmissionValidationErrorFragment } from '#graphql-operations'

const props = defineProps<{
  element: WebformElementTextBaseFragment
  formErrors: WebformSubmissionValidationErrorFragment[]
  prefilledInputs: InputPrefill[]
}>()

const { prefill } = useInputPrefill(props.prefilledInputs, props.element)

</script>

<style lang="scss">
@use "~/assets/sass/tools";

.form-item-email {
  @include tools.form-group-text;
}
</style>
